<template>
  <section class="hubspotform container flex flex-col mx-auto py-12 lg:py-24 px-6 2xl:max-w-8xl">
    <h2 class="text-5xl lg:text-7xl font-bold leading-3xl lg:leading-5xl" v-html="props.form.title" />
    <h3 class="text-1xl mt-6 text-" v-html="props.form.subtitle" />
    <div id="hbspt-form" />
  </section>
</template>

<script setup>
import { onMounted, ref } from "vue";

const props = defineProps({
  form: {
    type: Object,
  },
});

onMounted(async () => {
  const script = document.createElement("script");
  script.src = "https://js.hsforms.net/forms/v2.js";
  document.body.appendChild(script);
  script.addEventListener("load", () => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "5803045",
        formId: "25dccb0d-a847-4b48-af8c-1e842c1d6a5e",
        target: "#hbspt-form",
        errorClass: "border border-black rounded-7xl !w-full py-5 px-7 text-1xl invalid error",
        onFormReady: () => {
          let observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
              if (
                !mutation.target.classList.contains(
                  "border",
                  "border-black",
                  "rounded-7xl",
                  "!w-full",
                  "py-5",
                  "px-7",
                  "text-1xl",
                )
              ) {
                mutation.target.classList.add(
                  "border",
                  "border-black",
                  "rounded-7xl",
                  "!w-full",
                  "py-5",
                  "px-7",
                  "text-1xl",
                );
              }
            });
          });

          const form = document.querySelector("#hbspt-form");

          let formClass = document.querySelector(".hs-form-private");
          let fieldset = form.querySelectorAll("fieldset");
          let twoColumns = form.querySelectorAll(".form-columns-2 .hs-form-field");
          let oneColumn = form.querySelectorAll(".form-columns-1 .hs-form-field");
          let oneColumnInput = form.querySelectorAll(".form-columns-1 .hs-form-field .hs-input:not([type=checkbox])");
          let inputsContainer = form.querySelectorAll(".input");
          let inputs = form.querySelectorAll("input:not([type=checkbox])");
          let checkbox = form.querySelectorAll("input[type=checkbox]");
          let textarea = form.querySelectorAll("textarea");
          // let selectContainer = form.querySelector(".hs-fieldtype-intl-phone");
          let select = form.querySelectorAll("select");
          let label = form.querySelectorAll("label");
          let hsforms = form.querySelectorAll(".hs-form-field");

          hsforms.forEach((el) => {
            el.classList.add("!w-full");
          });

          let submit = form.querySelectorAll("input[type=submit]");

          inputs.forEach((input) => input.setAttribute("autocomplete", "off"));
          formClass.classList.add("my-12", "mx-auto", "w-full");
          fieldset.forEach((el) => {
            el.classList.add("flex", "gap-4", "!w-full", "!mb-4", "!max-w-none");

            let hasCityOrState = false;
            el.childNodes.forEach((input) => {
              if (input.classList && (input.classList.contains("hs_city") || input.classList.contains("hs_state"))) {
                hasCityOrState = true;
              }
            });

            if (hasCityOrState) {
              el.classList.add("flex-row");
            } else {
              el.classList.add("flex-col", "lg:flex-row");
            }
          });

          twoColumns.forEach((el) => {
            if (!el.classList.contains("hs_city") && !el.classList.contains("hs_state")) {
              el.classList.add("flex", "flex-col", "gap-2", "w-full", "lg:w-50");
            } else {
              el.classList.add("flex", "flex-col", "gap-2", "w-50");
            }
          });

          oneColumn.forEach((el) => el.classList.add("flex", "flex-col", "gap-2", "w-full"));
          oneColumnInput.forEach((el) => el.classList.add("!w-full"));
          inputsContainer.forEach((el) => el.classList.add("w-full", "!mr-0"));
          inputs.forEach((el) => {
            if (!el.classList.contains("hs-button"))
              el.classList.add("border", "border-black", "rounded-7xl", "!w-full", "py-5", "px-7", "text-1xl");

            observer.observe(el, {
              attributes: true,
              attributeFilter: ["class"],
            });
          });
          checkbox.forEach((el) => {
            el.classList.add("mr-4");
          });

          textarea.forEach((el) => {
            el.classList.add(
              "border",
              "border-black",
              "rounded-7xl",
              "!w-full",
              "py-5",
              "px-7",
              "text-1xl",
              "!max-w-none",
            );

            observer.observe(el, {
              attributes: true,
              attributeFilter: ["class"],
            });
          });
          // selectContainer.classList.add("flex");

          select.forEach((el) => {
            el.classList.add(
              "border",
              "border-black",
              "rounded-7xl",
              "!w-full",
              "py-5",
              "px-7",
              "text-1xl",
              "h-auto",
              "mr-auto",
            );
          });

          label.forEach((el) => {
            el.classList.add("text-1xl", "mb-4", "block");
          });

          submit.forEach((el) => {
            el.parentElement.classList.add("flex");

            el.setAttribute("style", "width: 240px !important");
            el.classList.add(
              "bg-black",
              "text-white",
              "rounded-7xl",
              "hover:text-primary-default",
              "transition-colors",
              "duration-300",
              "py-5",
              "px-7",
              "text-1xl",
              "mt-6",
              "mx-auto",
              "cursor-pointer",
            );
          });
        },
      });
    }
  });
});
</script>

<style lang="scss" scoped></style>